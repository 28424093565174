/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getEventById, updateEvent } from '@src/api/event';
import DeleteModal from '@src/components/DeleteModal';
import useCalculateDeliveryCharges from '@src/hooks/useDeliveryCharges';
import useMediaQuery from '@src/hooks/useMediaQuery';
import {
  BUFFET,
  BUFFET_FULL_NAME,
  DROP_OFF,
  DROP_OFF_FULL_NAME,
  statusCodes,
  updatePAXModalConfirmationMessage,
} from '@src/lib/constants';
import { getUpdateBody } from '@src/lib/getUpdate';
import {
  checkEditableOrder,
  formatDate,
  formatDates,
  formatNumber,
  getTotalAmount,
  groupByBookingDateAndTime,
} from '@src/lib/helper';
import { editIcon, emptyCartHero, handIcon, shoppingCart } from '@src/lib/imgUrl';
import { Box } from '@src/model/Box';
import { Booking } from '@src/model/Event';
import { Settings } from '@src/model/Setting';
import { updateEventAction } from '@src/redux/action/eventDetails';
import { ReduxProps } from '@src/redux/type';
import { theme } from '@src/styles/theme';

import { FoodBasicIcon, UpArrowIcon } from '../Icons';
import DownArrowIcon from '../Icons/DownArrowIcon';
import InvoiceCard from '../InvoiceCard';
import { InvoiceMessageProps } from '../InvoiceMessage';
import UpdatePAXModal from '../Modals/UpdatePAXModal';
import OrderDetails, { PackageMealCard } from '../OrderDetails';

import {
  StyledDate,
  StyledDateWrapper,
  StyledDivider,
  StyledEditContainer,
  StyledHeading,
  StyledHeadingContainer,
  StyledHeadingText,
  StyledMainContainer,
  StyledMakeMyOwnImg,
  StyledPackageType,
  StyledPackageTypeWrapper,
  StyledTextContainer,
} from './styles';

// eslint-disable-next-line complexity
const OrderList = ({
  setTotalAmount,
  showIncludedInPackage,
  selectedBooking,
  settings,
  invoiceMessage,
  isRealtimeBooking,
  isScrollable = false,
  showDropOffDeliveryFee = false,
  dropoffEditMode = false,
  mobileDropoff = false,
  eventIdProp,
}: {
  setTotalAmount?: Dispatch<SetStateAction<string | number>>;
  showIncludedInPackage?: boolean;
  isRealtimeBooking?: boolean;
  selectedBooking?: string;
  settings?: Settings;
  invoiceMessage?: InvoiceMessageProps;
  isScrollable?: boolean;
  showDropOffDeliveryFee?: boolean;
  dropoffEditMode?: boolean;
  mobileDropoff?: boolean;
  eventIdProp?: string;
}) => {
  const eventDetails = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const defaultDetails = useSelector((state: ReduxProps) => state?.bookingDetailsReducer?.booking);

  const isDropoff = eventDetails?.packageDetails?.service === DROP_OFF;

  const {
    numberOfPerson,
    startDate,
    endDate,
    makeMyOwn,
    Bookings,
    packageDetails: packageData,
    orderId,
    emirate,
    eventStatus,
    overridePricing,
    overridenAmount,
    perPersonCost,
    _id: _eventId,
  } = eventDetails;

  const { eventId } = useParams();

  const [eventFormatData, setEventFormatData] = useState<Record<string, Booking[]>>(() => {
    if (makeMyOwn && packageData?.service === DROP_OFF) {
      // const formattedDate = moment('2024-09-05').startOf('day').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      const bookings = !dropoffEditMode
        ? defaultDetails
        : defaultDetails && defaultDetails?.length > 0
        ? defaultDetails
        : Bookings || [];

      return { default: bookings };
    } else {
      const data = groupByBookingDateAndTime(
        isRealtimeBooking && showIncludedInPackage ? defaultDetails : Bookings || [],
        startDate,
        endDate
      );

      return data;
    }
  });

  const [show, setShow] = useState(true);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [showUpdatePAXModal, setShowUpdatePAXModal] = useState(false);

  const totalAmount = useMemo(() => {
    if (makeMyOwn && packageData.service === DROP_OFF) {
      return getTotalAmount(
        !dropoffEditMode ? defaultDetails : defaultDetails.length > 0 ? defaultDetails : Bookings!,
        true
      );
    }

    return getTotalAmount(
      isRealtimeBooking && showIncludedInPackage ? defaultDetails : (Bookings as Booking[]),
      makeMyOwn
    );
  }, [Bookings, makeMyOwn, defaultDetails]);

  const canEdit = checkEditableOrder(eventStatus?.name || '');

  canEdit
    ? useCalculateDeliveryCharges(eventFormatData, emirate, packageData?.service, settings)
    : orderId?.deliveryCost;

  const isDesktop = useMediaQuery(860);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleShow = useCallback(() => {
    setShow(prevShow => !prevShow);
  }, []);

  useEffect(() => {
    if (packageData?.service === DROP_OFF) {
      const bookings = !dropoffEditMode ? defaultDetails : defaultDetails.length > 0 ? defaultDetails : Bookings || [];

      setEventFormatData({ default: bookings });
    } else {
      setEventFormatData(
        groupByBookingDateAndTime(
          isRealtimeBooking && showIncludedInPackage ? defaultDetails : Bookings || [],
          startDate,
          endDate
        )
      );
    }
  }, [Bookings, defaultDetails]);

  useEffect(() => {
    setShow(isDesktop);
  }, [isDesktop]);

  const handleEditClick = useCallback(() => {
    setShowEditIcon(false);
    setShowEditConfirmModal(true);
  }, []);

  const handleEditModalClick = async () => {
    setShowUpdatePAXModal(true);
  };

  const handleEditModalClose = useCallback(() => {
    setShowEditConfirmModal(false);
  }, []);

  const refetchEventData = async (eventid: string) => {
    if (eventid) {
      const response = await getEventById({}, eventid);

      if (response.status === statusCodes.API_SUCCESS_CODE) {
        const result = response?.data;
        result && dispatch(updateEventAction(result));
      }
    }
  };

  const handleEditPAX = useCallback(
    async (paxNum: number) => {
      const eventid = eventIdProp && eventIdProp.length > 1 ? eventIdProp : eventId;

      try {
        if (eventid && eventid.length > 1) {
          await updateEvent(
            getUpdateBody({
              setData: {
                numberOfPerson: paxNum,
              },
            }),
            eventid
          );

          await refetchEventData(eventid);

          setShowUpdatePAXModal(false);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [dispatch, navigate, eventIdProp]
  );

  const isNegOrZeroTotal = (orderId?.discountValue ?? 0) >= totalAmount;

  const validDiscountAmount = isNegOrZeroTotal ? totalAmount : orderId?.discountValue ?? 0;
  const validDiscount = isNegOrZeroTotal ? totalAmount : orderId?.promoId?.discount ?? 0;
  // const validDiscountAmount = orderId?.discountValue ?? 0;
  // const validDiscount = orderId?.promoId?.discount ?? 0;
  const validDeliveryCost = isNegOrZeroTotal ? 0 : orderId?.deliveryCost ?? 0;

  return (
    <StyledMainContainer $isScrollable={isScrollable}>
      <StyledPackageTypeWrapper onMouseLeave={() => canEdit && setShowEditIcon(false)}>
        <DeleteModal
          isOpen={showEditConfirmModal}
          modalName={updatePAXModalConfirmationMessage}
          onRequestClose={handleEditModalClose}
          onSave={handleEditModalClick}
        />
        <UpdatePAXModal
          isOpen={showUpdatePAXModal}
          onRequestClose={() => {
            setShowUpdatePAXModal(false);
          }}
          onSave={handleEditPAX}
          numberOfGuests={numberOfPerson.toString()}
          settings={settings}
        />
        {packageData?.service === 'drop-off' && makeMyOwn ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontFamily: theme.fontFamily.lato,
                fontWeight: 700,
                fontSize: '20px',
                color: theme.primaryColor.shadePurple,
                marginRight: '10px',
              }}
            >
              Your Cart
            </span>
            <img src={shoppingCart} alt='shopping cart' height={20} width={20} />
          </div>
        ) : (
          <StyledPackageType onMouseEnter={() => canEdit && setShowEditIcon(true)}>
            {makeMyOwn ? (
              <StyledMakeMyOwnImg src={handIcon} alt='build my own icon' />
            ) : Bookings?.[0]?.packageDetails?.packageId?.picture?.url ? (
              <img src={Bookings?.[0]?.packageDetails?.packageId?.picture?.url} height={40} />
            ) : (
              <FoodBasicIcon height={'40'} width={'40'} />
            )}
            <StyledTextContainer>
              <StyledHeadingContainer>
                <StyledHeading>
                  {makeMyOwn ? 'Build Your Own' : eventDetails?.Bookings?.[0]?.packageDetails?.name}
                  <StyledHeadingText $hasMargin>
                    ({eventDetails?.packageDetails?.service === BUFFET ? BUFFET_FULL_NAME : DROP_OFF_FULL_NAME})
                  </StyledHeadingText>
                </StyledHeading>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4px' }}>
                  <StyledHeadingText>{formatNumber(numberOfPerson)} Guests</StyledHeadingText>
                  {/* <StyledEditContainer onClick={handleEditClick}>
                    <img src={editIcon} alt='edit-icon' />
                    <div>Edit</div>
                  </StyledEditContainer> */}
                </div>
              </StyledHeadingContainer>

              <StyledDateWrapper>
                <StyledDate>{formatDates(startDate, endDate)}</StyledDate>
                {!isDesktop && <div onClick={handleShow}>{show ? <DownArrowIcon /> : <UpArrowIcon />}</div>}
              </StyledDateWrapper>
            </StyledTextContainer>
          </StyledPackageType>
        )}
      </StyledPackageTypeWrapper>
      <div style={{ display: show || packageData.service === DROP_OFF ? 'block' : 'none' }}>
        {eventFormatData && Object.values(eventFormatData).length ? (
          <div>
            {Object.keys(eventFormatData).map((item: string) => {
              const bookings: Booking[] = eventFormatData?.default || eventFormatData[item];

              const serviceType = packageData?.service === BUFFET ? BUFFET : 'drop_off';

              const filteredBookings = bookings?.filter(
                booking => booking?.dishes.length !== 0 || booking?.boxes.length !== 0
              );

              return dropoffEditMode ? (
                <div>
                  {filteredBookings?.[0]?.dishes.map(dish => (
                    <PackageMealCard
                      name={dish.name}
                      numberOfPerson={dish.itemCount ?? 0}
                      packagesPrice={dish.dropOffPrice ?? 0}
                      dishId={dish.dishId}
                    />
                  ))}
                  {filteredBookings?.[0]?.boxes.map(box => {
                    const boxId = box.boxId as Box;
                    return (
                      <PackageMealCard
                        name={boxId?.name || ''}
                        numberOfPerson={box.itemCount ?? 0}
                        packagesPrice={boxId?.price ?? 0}
                        dishId={box._id}
                      />
                    );
                  })}
                </div>
              ) : filteredBookings?.length > 0 ? (
                isDropoff ? (
                  <div>
                    {filteredBookings?.[0]?.dishes?.map(dish => {
                      return (
                        <PackageMealCard
                          name={dish?.name}
                          numberOfPerson={dish?.itemCount ?? 0}
                          packagesPrice={dish?.dropOffPrice ?? 0}
                          dishId={dish?.dishId}
                        />
                      );
                    })}
                    {filteredBookings?.[0]?.boxes?.map(box => {
                      return (
                        <PackageMealCard
                          name={box?.name || ''}
                          numberOfPerson={box?.itemCount ?? 0}
                          packagesPrice={box?.price ?? 0}
                          dishId={box?._id}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <OrderDetails
                    key={item}
                    selectedBooking={selectedBooking}
                    bookings={filteredBookings}
                    label={isDropoff ? '' : formatDate(item)}
                    showIncludedInPackage={serviceType === BUFFET ? showIncludedInPackage : true}
                    isRealtimeBooking={isRealtimeBooking}
                    makeMyOwnPrice={settings?.minimumAmountforMakeMyOwn?.[serviceType]}
                  />
                )
              ) : null;
            })}
            <StyledDivider />
            <InvoiceCard
              totalAmount={overridePricing ? overridenAmount : totalAmount}
              isPriceOverriden={overridePricing}
              overridenSubtotal={overridenAmount}
              perPersonCost={perPersonCost ?? 0}
              setTotalAmount={setTotalAmount}
              invoiceMessage={invoiceMessage}
              discountAmount={validDiscountAmount}
              discountType={orderId?.promoId?.discountType ?? 'percentage'}
              discountValue={validDiscount}
              // deliveryCost={validDeliveryCost ?? 0}
              // showDropOffDeliveryFee={showDropOffDeliveryFee}
              isNegOrZeroTotal={isNegOrZeroTotal}
            />
          </div>
        ) : packageData.service === DROP_OFF && makeMyOwn ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '42px',
              marginBottom: '68px',
            }}
          >
            <img
              src={emptyCartHero}
              height={72}
              style={{ marginBottom: '8px' }}
              alt='man holding an empty shopping cart'
            />
            <div style={{ fontFamily: theme.fontFamily.lato, fontWeight: 500 }}>Your cart is currently empty</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </StyledMainContainer>
  );
};

export default OrderList;
